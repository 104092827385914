import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD0TMUf8-q6ZSPmGQmg6YJ93gIS2IBQs-Y",
    authDomain: "allied-health-ab4db.firebaseapp.com",
    databaseURL: "https://allied-health-ab4db-default-rtdb.firebaseio.com",
    projectId: "allied-health-ab4db",
    storageBucket: "allied-health-ab4db.appspot.com",
    messagingSenderId: "963427749581",
    appId: "1:963427749581:web:08a7b9fc7a0bf34578e384",
    measurementId: "G-9T7SDC9NLH"
  };

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firedb = firebase.firestore();